import Axios from "axios";
import { removePTag } from "./removePTag";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const GetAllTestimonialService = () => {
	return Axios.get(API_URL + `/testimonial`);
};

export const UpdateTestimonialService = (values) => {
	values.description = removePTag(values.description);
	return Axios.put(API_URL + "/testimonial", values);
};

export const GetSingleTestimonial = (id) => {
	return Axios.get(API_URL + `/testimonial/single/${id}`);
};

export const DeleteTestimonialService = (id) => {
	return Axios.delete(API_URL + `/testimonial/${id}`);
};

export const CreateTestimonialService = (values) => {
	values.description = removePTag(values.description);
	return Axios.post(API_URL + "/testimonial", values);
};
