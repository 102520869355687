import React, { useState } from "react";

/// React router dom
import { Switch, Route, withRouter } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Analytics from "./components/Dashboard/Analytics";
import Reviews from "./components/Dashboard/Reviews";
import Order from "./components/Dashboard/Order";
import OrderList from "./components/Dashboard/OrderList";
import CustomerList from "./components/Dashboard/CustomerList";
import EcomProductDetails from "./components/Dashboard/EcomProductDetails";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import BtcChart from "./components/charts/apexcharts/ApexChart";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import RechartJs from "./components/charts/rechart";

/// Widget
import Widget from "./pages/Widget";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import ApexChart from "./components/charts/apexcharts";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import DataComponent from "./pages/Data";

// choose city page
import ChooseCity from "./pages/ChooseCity";
// Homepage
// Section1
import EditSectionOne from "./pages/HomePage/Section1/Edit";
import SectionOneContent from "./pages/HomePage/Section1/View";
// Menus
import EditMenus from "./pages/HomePage/Menus/Edit";
import Menus from "./pages/HomePage/Menus/View";
// Menus Content
import EditMenusContent from "./pages/HomePage/MenusContent/Edit";
import MenusContent from "./pages/HomePage/MenusContent/View";
// Questions
import EditFaq from "./pages/HomePage/Faq/Edit";
import FaqPage from "./pages/HomePage/Faq/View";
// Questions
import EditResultMenuContent from "./pages/ResultPage/MenusContent/Edit";
import ResultMenuContent from "./pages/ResultPage/MenusContent/View";
// Profile
import EditProfile from "./pages/Profile/Edit";
import Profile from "./pages/Profile/View";
// FAq
import EditFAQ from "./pages/Faq/Edit";
import AddFaq from "./pages/Faq/Add";
import FAQ from "./pages/Faq/View";
// City
import EditCity from "./pages/City/Edit";
import AddCity from "./pages/City/Add";
import City from "./pages/City/View";

// Testimonials
import EditTestimonial from "./pages/Testimonials/Edit";
import AddTestimonial from "./pages/Testimonials/Add";
import Testimonials from "./pages/Testimonials/View";

// Contact
import Contact from "./pages/Contact";
// Support
import Faq from "./pages/Support/Faq";
import Videos from "./pages/Support/Videos";
import Issues from "./pages/Support/Issues";

// Users
import Users from "./pages/Users";

// Audit
import ViewAudits from "./pages/Audit/ViewAudits";
import ViewUsers from "./pages/Audit/ViewUsers";
// import City from "./pages/City/View";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "analytics", component: Analytics },
    { url: "reviews", component: Reviews },
    { url: "order", component: Order },
    { url: "order-list", component: OrderList },
    { url: "general-customers", component: CustomerList },
    // { url: "ecom-product-detail", component: EcomProductDetails },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-btc", component: BtcChart },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    { url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Homepage
    { url: "edit-section-one", component: EditSectionOne },
    { url: "section-one", component: SectionOneContent },
    { url: "edit-menus/:id", component: EditMenus },
    { url: "menus", component: Menus },
    { url: "edit-menus-content/:id", component: EditMenusContent },
    { url: "menus-content/:id", component: MenusContent },
    { url: "faq", component: FaqPage },
    { url: "edit-faq/:id", component: EditFaq },
    // ResultPage
    { url: "result-menu-content/:id", component: ResultMenuContent },
    { url: "edit-result-menu-content/:id", component: EditResultMenuContent },
    // Profile page
    { url: "edit-profile", component: EditProfile },
    { url: "profiles", component: Profile },
    // FAQ page
    { url: "edit-faqs/:id", component: EditFAQ },
    { url: "add-faq", component: AddFaq },
    { url: "faqs/:id", component: FAQ },
    // City page
    { url: "edit-city/:id", component: EditCity },
    { url: "add-city", component: AddCity },
    { url: "city/:id", component: City },
    // Testimonials page
    { url: "edit-testimonial/:id", component: EditTestimonial },
    { url: "add-testimonial", component: AddTestimonial },
    { url: "testimonials/:id", component: Testimonials },
    // choose city page
    { url: "choose-city-page", component: ChooseCity },

    /// Support
    { url: "faq", component: Faq },
    { url: "videos", component: Videos },
    { url: "issues", component: Issues },

    /// Data
    { url: "data-content", component: DataComponent },

    /// Contact
    { url: "contact", component: Contact },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    {url: "view-audits", component: ViewAudits},

    {url: "view-audit-users/:id", component: ViewUsers},

  ];

  return (
    <div
      id={`${!pagePath ? "main-wrapper" : ""}`}
      className={`${!pagePath ? "show" : "mh100vh"}`}
    >
      {!pagePath && (
        <Nav
          onClick={() => setActiveEvent(!activeEvent)}
          activeEvent={activeEvent}
          onClick2={() => setActiveEvent(false)}
          onClick3={() => setActiveEvent(true)}
        />
      )}
      <div
        className={` ${!path && activeEvent ? "rightside-event" : ""} ${
          !pagePath ? "content-body" : ""
        }`}
      >
        <div className={`${!pagePath ? "container-fluid" : ""}`}>
          <Switch>
            {routes.map((data, i) => (
              <Route
                key={i}
                exact
                path={`/${data.url}`}
                component={data.component}
              />
            ))}
          </Switch>
        </div>
      </div>
      {!pagePath && <Footer />}
    </div>
  );
};

export default withRouter(Markup);
