import React, { Component } from "react";
/// Link
import { Link, withRouter } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Menu
import MetisMenu from "metismenujs";
import { PageContentByService } from "../../services/menus";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

// var menuTitles = [];
class SideBar extends Component {
  /// Open menu
  constructor(props) {
    super(props);
    this.state = {
      menuTitles: [],
      resultPageMenus: [],
      localStorage: localStorage.getItem("cityId"),
      loader: false,
    };
  }
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    const cityId = localStorage.getItem("cityId");
    PageContentByService(cityId, "menu")
      .then((response) => {
        this.setState({ menuTitles: response?.data?.data?.pages });
      })
      .catch((error) => {
        console.log("error", error);
      });
    PageContentByService(cityId, "result")
      .then((response) => {
        this.setState({ resultPageMenus: response?.data?.data?.pages });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  componentDidUpdate(prevProps, props) {
    const cityId = localStorage.getItem("cityId");
    const menuUpdated = localStorage.getItem("menuTitle");
    if (this.state.localStorage !== cityId || menuUpdated) {
      PageContentByService(cityId, "menu")
        .then((response) => {
          localStorage.removeItem("menuTitle");
          this.setState({
            localStorage: cityId,
            menuTitles: response?.data?.data?.pages,
          });
        })
        .catch((error) => {
          this.setState({
            menuTitles: [],
          });
        });
    }
    // if(menuUpdated){
    // 	console.log("test");
    // }
  }

  render() {
    /// Path
    const { menuTitles, loader, resultPageMenus } = this.state;
    const id = localStorage.getItem("cityId");
    let path = window.location.pathname;
    let cityName = localStorage.getItem("cityName");
    let currentPath = path.split("/");
    path = currentPath[currentPath.length - 1];
    /// Active menu
    let homePage = [
        "section-one",
        "edit-section-one",
        "edit-menus",
        "menus",
        "edit-menus-content",
        "menus-content",
        "faq",
      ],
      profilePage = ["profiles", "edit-profile"],
      resultPage = ["result-menu-content", "edit-result-menu-content"],
      city = ["city", "edit-city", "add-city"],
      testimonials = ["testimonials", "edit-testimonial", "add-testimonial"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${homePage.includes(path) ? "mm-active" : ""}`}>
              <Link to="#" className="has-arrow ai-icon" aria-expanded="false">
                <i className="flaticon-381-home"></i>
                <span className="nav-text">Home Page ({cityName})</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link
                    className={`${path === "menus" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/menus"
                  >
                    Menu Titles
                  </Link>
                </li>
                {menuTitles?.map((menus, index) => (
                  <li key={index}>
                    {
                      <Link
                      className={`${
                        path === `menus-content/${menus?.id}` ? "mm-active" : ""
                      }`}
                      onClick={() => {
                        localStorage.setItem("menuId", menus?.id);
                        this.props.onClick();
                      }}
                      to={`/menus-content/${menus?.id}`}
                    >
                      {menus?.title}
                    </Link>
                    }
                    
                  </li>
                ))}
              </ul>
            </li>

            <li className={`${resultPage.includes(path) ? "mm-active" : ""}`}>
              <Link to="#" className="has-arrow ai-icon" aria-expanded="false">
                <i className="flaticon-381-home"></i>
                <span className="nav-text">Result Page ({cityName})</span>
              </Link>
              <ul aria-expanded="false">
                {resultPageMenus?.map((menus, index) => (
                  <li key={index}>
                    <Link
                      className={`${
                        path === `result-menu-content/${menus?.id}`
                          ? "mm-active"
                          : ""
                      }`}
                      onClick={() => {
                        localStorage.setItem("menuId", menus?.id);
                        this.props.onClick();
                      }}
                      to={`/result-menu-content/${menus?.id}`}
                    >
                      {menus?.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li
              className={`${
                currentPath[1] == "testimonials" ? "mm-active" : ""
              }`}
            >
              <Link
                to={`/testimonials/${id}`}
                className="ai-icon"
                aria-expanded="false"
              >
                <i className="flaticon-381-home"></i>
                <span className="nav-text">Testimonials</span>
              </Link>
            </li>
            <li className={`${currentPath[1] == `faqs` ? "mm-active" : ""}`}>
              <Link
                to={`/faqs/${id}`}
                className="ai-icon"
                aria-expanded="false"
              >
                <i className="flaticon-381-home"></i>
                <span className="nav-text">FAQ</span>
              </Link>
            </li>

            <li className={`${currentPath[1] == "city" ? "mm-active" : ""}`}>
              <Link
                to={`/city/${id}`}
                className="ai-icon"
                aria-expanded="false"
              >
                <i className="flaticon-381-home"></i>
                <span className="nav-text">City</span>
              </Link>
            </li>
            <li className={`${currentPath[1] == "city" ? "mm-active" : ""}`}>
              <Link
                to={`/view-audits`}
                className="ai-icon"
                aria-expanded="false"
              >
                <i className="flaticon-381-home"></i>
                <span className="nav-text">Audit</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default withRouter(SideBar);
